import type { Image } from "~/@types/image"

export default (imageRef?: string): Image => {
  if (!imageRef) {
    return {} as Image
  }

  const [, id, sizes, ext] = imageRef.split("-")
  const [w, h] = sizes.split("x")

  return {
    id,
    sizes: {
      w: +w,
      h: +h,
    },
    ext,
  }
}
